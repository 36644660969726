import React from "react";
import { graphql } from "gatsby";
import { Search } from "gatsby-parallelpublicworks/src/components";

function SearchPage({ data }) {
  let _data = { allEntityRef: { edges: [] } };
  _data.allEntityRef.edges = [...data.allEntityRef.edges, ...data.allVariantRef.edges];
  return <Search data={_data} />;
}

export default SearchPage;
export const query = graphql`
  query {
    allEntityRef(filter: { private: { eq: false } }) {
      edges {
        node {
          id
          slug
        }
      }
    }
    allVariantRef(filter: { private: { eq: false } }) {
      edges {
        node {
          id
          slug
        }
      }
    }
  }
`;
